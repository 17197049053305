<template>
  <header>
    <div class="applogo">
    </div>
    <div>
      <a id="userprofile" @click="showMenu = true">{{ userInitials }}</a>
      <div id="usermenu" v-if="showMenu">
        <div id="userprofile-overlay" @click="showMenu = false"></div>
        <div id="userprofile-menu" class="popout">
          <div class="popout-section">{{ userName }}<br/><span class="email">{{ email }}</span></div>

          <div class="popout-section">
            <button @click="signOut()" v-if="isSignedIn" style="display: inline;">Sign Out</button>
            <button @click="signIn()" v-if="!isSignedIn" style="display: inline;">Sign In</button>
          </div>
          <div class="app-version" :title="appSemVer"><span>v{{appVersion}}</span> <span>{{appVersionHash}}</span></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Okta from "@/services/okta";

export default {
  name: "AppHeader",
  components: {
  //  AppMenu
  },
  data() {
    return {
      showMenu: false,
      isSignedIn: true,
      userInitials: "",
      userName: "",
      picture: null,
      email: "",
      appVersion: process.env.VUE_APP_GIT_VERSION,
      appSemVer: process.env.VUE_APP_GIT_SEMVER,
      appVersionHash: process.env.VUE_APP_GIT_VERSION_HASH
    };
  },
  async mounted() {
    let self = this;
    try {
      this.isSignedIn = await Okta.IsSignedIn();
      if(this.isSignedIn) {
        let idToken = await Okta._GetUserData();
        self.userInitials = idToken.profile.given_name.substring(0, 1);
        self.userName = idToken.profile.name;
        self.email = idToken.profile.email;
      }
    } catch(err) {
      console.log(err);
    }
  },
  methods: {
    signOut() {
      Okta.SignOut();
      location.href = "/";
    },
    signIn() {
      Okta.SignIn();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#userprofile {
  display: block;
  border: 3px white solid;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 2em;
  background: rgb(0, 157, 224);
  color: white;
  text-align: center;
  user-select: none;
  cursor: pointer;
  font-family: 'omnes-pro', sans-serif;
}
#userprofile:hover {
  border: 3px #eee solid;
}

#userprofile-overlay {
  pointer-events: none;
  background: rgba(15, 23, 42, 0);
  position: fixed;

  z-index: 50;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  opacity: 0.5;
  pointer-events: all;
}
#userprofile-menu {
  z-index: 51;
  text-align: center;
  min-width: 10em;
  position: absolute;
  right: 1ex;
  font-size: 15px;
  line-height: 100%;
}

#userprofile-menu .email {
  font-size: smaller;
}

header {
  padding: 1.5ex 1.5ex;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  height: 42px;

  font-family: omnes-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.625em;
  color: #202125;
}

.applogo {
  height: 100%;
  width: 175px;

  background: url("../../public/static/dd_wolt_color.png");
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
}

.logout {
  order: 1;
  padding-top: 7px;

  font-size: 14px;
}

.logout svg {
  transition: fill 96ms ease-in 0s;
  width: 12px;
  height: 12px;
}

.popout {
  min-height: 30px;
  padding: 0px;
  color: rgb(32, 33, 37);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(32 33 37 / 8%) 0px 0px 0px 1px,
    rgb(32 33 37 / 12%) 0px 4px 14px -2px;
}

.popout-section {
  padding: 12px 16px;
  flex-shrink: 0;
}

.app-version {
  color: gray;
  font-size: 10px;
  height: 15px;
  line-height: 10px;
  text-align: left;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
}

.app-version span {
  margin-left: 1em;
}
</style>